import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Logo from "../images/logo-concrete-content.svg";
import BetonNews from "../images/logo-beton-fachzeitschrift.svg";

function IndexPage() {
  return (
    <Layout>
      <SEO title="concrete content | Verlag & Kommunikation" />
      <div className="w-full">
        <img
          alt="Concrete Content – Verlag & Kommunikation"
          className="block w-1/2 mx-auto mb-8"
          src={Logo}
        />
      </div>
      <div className="w-full mt-8 mx-auto max-w-xl">
        <a href="https://beton.news" target="_blank" rel="noreferrer">
          <img
            alt="Beton"
            className="block w-1/2 mx-auto mb-8"
            src={BetonNews}
          />
        </a>
      </div>
    </Layout>
  );
}

export default IndexPage;
